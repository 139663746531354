body {
  align-items: flex-start;
  font-size: 12px;
  background-color: #fff;
}
body::after {
  display: block;
  content: '';
  position: fixed;
  border: 25px solid transparent;
  border-right-color: var(--main-color);
  border-top-color: var(--main-color);
  height: 0;
  width: 0;
  top: 0;
  right: 0;
}
main {
  align-self: end;
}
.page {
  box-shadow: none;
  box-sizing: border-box;
  grid-gap: 0 2rem;
  min-height: 100vh;
  padding: 2rem;
}
.page::before {
  content: '';
  background: #fafafa;
  grid-area: header / header / aside;
  margin: -2rem -1rem -2rem -2rem;
  -webkit-print-color-adjust: exact;
}
.tech {
  flex-basis: 120px;
}
.hobby {
  flex-basis: 60px;
}
.work__logo {
  mix-blend-mode: luminosity;
  filter: none;
}
#👇 {
  display: none;
}
@page {
  margin: 0;
  size: A4;
}
